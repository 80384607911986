.attributes-main {
  display: flex;
  flex-direction: row;
  height: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  justify-content: center;
  .attribute {
    margin: 10px;
    margin-right: 10px;
    font-size: x-large;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--main-brand-offset-color);
    border: 1px solid white;
    padding: 15px;
    width: 250px;
    .attribute-icon {
      margin-right: 10px;
      font-size: 3rem;
      color: var(--main-brand-color);
    }
  }
}
@media only screen and (max-width: 600px) {
  .attributes-main {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 25px;
    .attribute {
      font-size: small;
      width: 150px;
      padding: 10px;
      .attribute-icon {
        margin-right: 0px;
        font-size: 1.5rem;
      }
    }
  }
}
