.main-banner-main {
  margin-top: 3rem;
  background: url('assets/images/Banner.png');
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;

  .main-banner-text {
    display: flex;
    align-items: center;
    z-index: 5;
    width: 35%;
    margin-left: 10%;
    height: 25vw;
    font-size: x-large;
    color: white;
    font-weight: 800;
  }

  .banner {
    position: relative;
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .main-banner-main {
    .main-banner-text {
      font-size: small;
    }
  }
}
