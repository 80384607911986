.main-class {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: max-content;
}
.copyright {
  font-size: xx-small;
  margin: auto;
}
