.product-main {
  display: flex;
  flex-direction: row;
  flex-basis: 50%;
  margin-left: 8%;
  margin-right: 8%;
  margin-top: 3%;
  align-items: center;
  justify-content: center;
  border-top: 1px solid white;
  padding-top: 2%;
  .product-img-container {
    width: 50%;
    display: flex;
    align-content: center;
    justify-content: right;
    margin-right: 10px;
    .product-img {
      width: 75%;
    }
  }
  .product-img-container-after {
    justify-content: left;
    margin-left: 20px;
  }
  .product-text {
    .product-header {
      font-size: x-large;
      font-weight: 600;
    }
    width: 50%;
    text-align: right;
    margin-right: 10px;
  }
  .product-after {
    text-align: left;
    margin-left: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .product-main {
    .product-text {
      .product-header {
        font-size: medium;
      }
      font-size: x-small;
    }
    .product-img-container {
      width: 70%;
      justify-content: center;
    }
    .product-img-container-after {
      justify-content: center;
      margin-left: 5px;
    }
  }
}
