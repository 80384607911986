.about-us-main {
  display: flex;
  flex-direction: column;
  margin-top: 2%;
  margin-bottom: 2%;
  align-items: center;
  .about-us-title {
    margin-bottom: 1%;
    font-size: x-large;
    font-weight: 600;
  }
  .about-us-text {
    border-top: 1px solid white;
    padding-top: 1%;
    margin-left: 8%;
    margin-right: 8%;
    font-size: medium;
    text-align: center;
  }
}

@media only screen and (max-width: 600px) {
  .about-us-main {
    .about-us-title {
      font-size: medium;
    }
    .about-us-text {
      font-size: small;
    }
  }
}
