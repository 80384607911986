.header-main {
  position: fixed;
  display: flex;
  gap: 50px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: var(--main-brand-offset-color);
  height: 3rem;
  font-size: 0.8rem;
  color: var(--main-brand-color);
  z-index: 10;

  .logo-container {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    .logo {
      margin-left: 10%;
      width: 2rem;
    }
    .logo-name {
      color: var(--main-brand-color);
      font-size: 1rem;
      font-weight: 700;
      margin-left: 0.5rem;
    }
  }
  .icons {
    display: flex;
    gap: 15px;
    .icon-link {
      text-decoration: inherit;
      color: inherit;
      color: var(--main-brand-color);
      font-size: 1.2rem;
      :visited {
        color: var(--main-brand-color);
      }
      .icon {
        color: var(--main-brand-color);
        font-size: 1.2rem;
        cursor: pointer;
      }
    }
  }
  .translate {
    display: flex;
    gap: 10px;
    margin-right: 10px;
  }
  .translate-btn {
    padding-left: 2px;
    padding-right: 2px;
    padding-bottom: 2px;
    padding-top: none;
    background-color: var(--main-brand-offset-color);
    border: 1px solid var(--main-brand-color);
    display: inline;
    cursor: pointer;
  }
}
