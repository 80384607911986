.footer-main {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: var(--main-brand-offset-color);
  height: 20rem;
  font-size: 0.8rem;
  color: var(--main-brand-color);
  z-index: 10;
  margin-top: 50px;

  .map {
    width: 50%;
    display: flex;
    justify-content: center;
  }
  .contact-us {
    text-align: center;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .email-btn {
      padding: 5px;
      margin-top: 5px;
      background-color: var(--main-brand-offset-color);
      color: var(--main-brand-color);
      border-color: var(--main-brand-color);
      margin: auto;
      cursor: pointer;
    }
    .working-hours {
      margin-top: 15px;
      display: flex !important;
      flex-direction: column;
    }
  }
}
.marker {
  transform: perspective(40px) rotateX(20deg) rotateZ(-45deg);
  transform-origin: 50% 50%;
  border-radius: 50% 50% 50% 0;
  padding: 0 3px 3px 0;
  width: 20px;
  height: 20px;
  background: var(--main-brand-color);
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -2.2em 0 0 -1.3em;
  -webkit-box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.5);
  box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.5);
}

.marker:after {
  content: '';
  width: 1em;
  height: 1em;
  margin: 0.6em 0 0 0.5em;
  background: #ffffff;
  position: absolute;
  border-radius: 50%;
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: inset -2px 2px 4px hsla(0, 0, 0, 0.5);
  -webkit-box-shadow: inset -2px 2px 4px hsla(0, 0, 0, 0.5);
  box-shadow: inset -2px 2px 4px hsla(0, 0, 0, 0.5);
}
.beacon {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 2.5em;
  width: 2.5em;
  border-radius: 50%;
  -webkit-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: -1;
}

.beacon:before,
.beacon:after {
  position: absolute;
  content: '';
  height: 1.9em;
  width: 1.9em;
  left: 0;
  top: 0;
  background-color: transparent;
  border-radius: 50%;
  box-shadow: 0 0 0 3px var(--main-brand-color);
  -webkit-animation: active 2s infinite linear;
  -moz-animation: active 2s infinite linear;
  animation: active 2s infinite linear;
}

.beacon:after {
  -moz-animation-delay: 1s;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

@-webkit-keyframes active {
  0% {
    -webkit-transform: scale(0.1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2.5);
    opacity: 0;
  }
}

@keyframes active {
  0% {
    transform: scale(0.1);
    transform: box-shadow(0px 0px 0px 1px var(--main-brand-color));
    opacity: 1;
  }
  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}

@media only screen and (max-width: 600px) {
  .footer-main {
    gap: 20px;
    .map {
      /* height: 15rem; */
      #inner-map {
        height: 200px !important;
      }
      width: 40%;
    }
    .contact-us {
      width: 40%;
    }
  }
}
